import { useTranslation } from 'react-i18next';
import { EditQuotaFormProps } from './types';
import React, { useEffect, useMemo, useState } from 'react';
import { useErrorContext } from 'components/tasks/ErrorContext';
import { IssueSource, QuotaCalculationResult, QuotaStatus } from 'schema/serverTypes';
import { useQuotaCalculatorForm } from './useQuotaCalculatorForm';
import { CalculationForm } from '../CalculationForm';
import { Box, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import {
  useTabs,
  Tabs,
  TabPanel,
  Button,
  IconBackTo,
  IconSprite,
  RadioOption,
  Radio,
  useModalForm,
  ModalForm,
  useToast,
} from 'components';
import SwipeableViews from 'react-swipeable-views';
import { ContractForm } from 'components/calculator2/ContractForm';
import { ShipmentForm } from 'components/calculator2/ShipmentForm';
import { useSetQuotaStatusMutation } from 'services/api';
import { ChangeStatusForm } from './ChangeStatusForm';
import { FieldValues, useForm } from 'react-hook-form';
import { Approval } from '../Approval';
import { PageTitle } from 'components/utils/pageTitle';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { QualificationPanel } from '../Qualification/QualificationPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quotaInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1.5),
    },
    span: {
      fontFamily: 'Roboto, san-serif',
      fontSize: 12,
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.6),
    },
    button: {
      padding: 0,
    },
    issueInfoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      background: 'white',
      padding: theme.spacing(0, 2.5),
      borderBottom: '2px solid #E3E6F3',
      marginBottom: theme.spacing(2.5),
      fontWeight: 600,
      fontSize: 16,
      color: theme.palette.primary.main,
      lineHeight: '46px',
      '& a': {
        color: theme.palette.primary.main,
      },
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginBottom: 2,
    },
    contractNumber: {
      fontWeight: 700,
    },
    documentUrl: {
      marginLeft: theme.spacing(1.5),
    },
    quotaStatus: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1.5),
      minHeight: 44,
    },
  }),
);

type QuotaInfoProps = {
  userName?: string;
  calculationNumber: number;
  status?: QuotaStatus;
};

type IssueInfoProps = {
  contractNumber?: string;
  issueId?: number;
  documentUrl?: string;
  contractFolderUrl?: string;
};

export const EditQuotaForm = (props: EditQuotaFormProps) => {
  const { quota, shipmentItemId, isTask } = props;
  const { t } = useTranslation();
  const [isContractDirty, setIsContractDirty] = useState(false);
  const [isShipmentDirty, setIsShipmentDirty] = useState(false);
  const tabs = [
    {
      name: t('Calculation'),
    },
    {
      name: t('Approval'),
    },
    {
      name: t('Contract'),
      confirmation: isContractDirty,
    },
    {
      name: t('Shipment'),
      confirmation: isShipmentDirty,
    },
  ];

  const getInitialTab = (
    state: { tab?: string; timeStamp?: number } | null,
    hash: string,
    tabs: { name: string }[],
  ) => {
    window.history.replaceState({}, '');
    if (state?.tab) {
      return tabs.findIndex((item) => item.name === state.tab);
    }

    return hash === '#approval' ? 1 : 0;
  };

  const { state, hash } = useLocation<{ tab?: string; timeStamp?: number }>();
  const initialTab = getInitialTab(state, hash, tabs);
  const tabsProps = useTabs(tabs, initialTab, state?.timeStamp);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;
  const {
    quotaId,
    issueId,
    contractNumber,
    contractFolderUrl,
    documentUrl,
    user: { name: userName },
    status,
  } = quota;

  if (!isTask) {
    const pageTitle =
      t('Quota') + ' №' + props.quota.quotaId + ' ' + (props.quota.lesseeName ?? '');
    PageTitle(pageTitle);
  }

  const title = t('Calculation');

  useErrorContext({
    title,
    source: IssueSource.quota,
    quotaId: quotaId,
  });

  return (
    <Grid container direction="column">
      {!isTask && (
        <Grid item>
          <QuotaInfo userName={userName} calculationNumber={quotaId} status={status} />
          <IssueInfo
            issueId={issueId}
            contractNumber={contractNumber}
            contractFolderUrl={contractFolderUrl}
            documentUrl={documentUrl}
          />
        </Grid>
      )}
      <Box mb={2.5} display="flex" justifyContent="space-between" alignItems="center">
        <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
      </Box>
      <Grid item>
        <SwipeableViews
          containerStyle={{
            transition: 'transform 0.6s ease-out 0s',
          }}
          springConfig={{
            duration: '0.6s',
            easeFunction: 'transform 0.6s ease-out 0s',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={onChangeTabIndex}
        >
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            {tabIndex === 0 && <CalculatorForm quota={quota} isTask={isTask ?? false} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} dir={theme.direction}>
            {tabIndex === 1 && <Approval quota={quota} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={2} dir={theme.direction}>
            {tabIndex === 2 && (
              <ContractForm
                quota={quota}
                drawUpEnabled={true}
                setIsDirty={setIsContractDirty}
                isTask={isTask ?? false}
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={3} dir={theme.direction}>
            {tabIndex === 3 && (
              <ShipmentForm
                quota={quota}
                shipmentItemId={shipmentItemId}
                isTask={isTask}
                setIsDirty={setIsShipmentDirty}
              />
            )}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

export const QuotaInfo = (props: QuotaInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { status, calculationNumber = t('NoCalculation') } = props;
  const { handleSubmit, control, watch } = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: { status },
  });

  const { onOpen, onClose, open } = useModalForm();
  const queryClient = useQueryClient();
  const { mutateAsync } = useSetQuotaStatusMutation({
    onSuccess: () => {
      toast(t('Status changed'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('quotas') > -1;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: FieldValues) => {
      try {
        if (form.status === QuotaStatus.Active || form.status === QuotaStatus.Archived) {
          await mutateAsync({
            quotaId: [calculationNumber],
            ...form,
          });
        } else {
          onOpen();
        }
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, calculationNumber, onOpen]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit as any)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onSubmit]);

  return (
    <>
      <div className={classes.quotaInfo}>
        <div>
          <Button variant="iconButton" endIcon={<IconBackTo />} to="/quotas" />
          <span className={classes.span}>
            {t('Calculation')} №{calculationNumber}
          </span>
        </div>
        <div className={classes.quotaStatus}>
          <form>
            <Radio
              name="status"
              control={control}
              defaultValue={QuotaStatus.Active}
              options={[
                {
                  label: `${t('QuotaStatus.Active')}`,
                  value: QuotaStatus.Active,
                } as RadioOption,
                {
                  label: `${t('QuotaStatus.Lost')}`,
                  value: QuotaStatus.Lost,
                } as RadioOption,
                {
                  label: `${t('QuotaStatus.Archived')}`,
                  value: QuotaStatus.Archived,
                } as RadioOption,
              ]}
            />
          </form>
        </div>
      </div>
      <ModalForm open={open} onClose={onClose}>
        <ChangeStatusForm onSuccess={onClose} quotaId={[calculationNumber]} />
      </ModalForm>
    </>
  );
};

export const IssueInfo = (props: IssueInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { contractNumber, contractFolderUrl, documentUrl } = props;

  if (!contractNumber) return null;

  return (
    <div className={classes.issueInfoWrapper}>
      <div className={classes.contractNumber}>
        {t('Contract')} №{contractNumber}
      </div>
      <div>
        {contractFolderUrl && (
          <a href={contractFolderUrl} target="_blank" rel="noreferrer">
            {t('Contract documents')}
            <IconSprite
              className={classes.icon}
              icon={'view'}
              width={'15px'}
              height={'10px'}
              color={theme.palette.text.secondary}
            />
          </a>
        )}
        {documentUrl && (
          <a href={documentUrl} target="_blank" rel="noreferrer" className={classes.documentUrl}>
            {t('Client documents')}
            <IconSprite
              className={classes.icon}
              icon={'view'}
              width={'15px'}
              height={'10px'}
              color={theme.palette.text.secondary}
            />
          </a>
        )}
      </div>
    </div>
  );
};

type CalculatorFormProps = {
  quota: QuotaCalculationResult;
  isTask: boolean;
};

const CalculatorForm = ({ quota, isTask }: CalculatorFormProps) => {
  const {
    onSubmit,
    defaultValues,
    error,
    isLoading,
    calculationResult,
    resultRef,
    form,
    approvalErrorPopup,
  } = useQuotaCalculatorForm(quota);

  return (
    <>
      <QualificationPanel
        quotaId={quota.quotaId}
        createdDate={quota.createdDate}
        author={quota.user}
        dealQualificationStatus={quota.dealQualificationStatus}
        lessee={quota.lesseeName}
        leaseSubject={quota.calculationResult.leaseSubject}
        scoringModelName={quota.calculationResult.scoringModelName}
      />
      <CalculationForm
        ref={resultRef}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={error}
        isLoading={isLoading}
        isLocked={quota.isLocked}
        isContractExecutionNotified={quota.isContractExecutionNotified}
        calculationResult={calculationResult}
        nomenclatures={quota.input.nomenclatures}
        copyEnabled={true}
        drawUpEnabled={!quota.issueId}
        sendToIsEnabled={!quota.scoringIssueId}
        approvalEnabled={!!quota.scoringIssueId && !quota.scoringApprovalIssueId}
        quotaId={quota.quotaId}
        form={form}
        isTask={isTask}
        issueId={quota.issueId}
        scoringApprovalIssueId={quota.scoringApprovalIssueId}
        approvalErrorPopup={approvalErrorPopup}
        scoringIssueId={quota.scoringIssueId}
      />
    </>
  );
};
