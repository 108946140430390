import { useErrorContext } from 'components/tasks/ErrorContext';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { IssueSource, QuotaCalculationResult } from 'schema/serverTypes';
import { CreateQuotaForm, CurrencyExchangerProvider, EditQuotaForm } from 'components/calculator2';
import {
  useCofsQuery,
  useCurrencyRatesQuery,
  useQuotasBackendQuery,
} from 'services/api/useQuotasBackend';
import { Critical, QuotaCommentFormPanel, QuotaOwnerFormPanel } from 'components';
import { QuotaDataGrid } from 'components/calculator/QuotaDataGrid';
import { DrawUpContract } from 'components/calculator2/ContractForm/DrawUpContract';
import { PageTitle } from 'components/utils/pageTitle';

const QuotaListPage = (props: RouteComponentProps) => {
  const path = props.location.pathname;
  const changeOwnerPath = `/quotas/calculator/owner/:id(\\d+)`;
  const commentsPath = `/quotas/calculator/comments/:id(\\d+)`;
  const { t } = useTranslation();
  const pageTitle = t('Section.quotas') + ' - Bumblebee';
  PageTitle(pageTitle);

  return (
    <>
      <Critical />
      <Route path={path} component={QuotaDataGrid} />
      <Route exact path={commentsPath} component={QuotaCommentFormPanel} />
      <Route exact path={changeOwnerPath} component={QuotaOwnerFormPanel} />
    </>
  );
};

const CreateQuotaPage = () => {
  const { data: currencies, isLoading } = useCurrencyRatesQuery();
  const { data: cofs, isLoading: isLoadingCofs } = useCofsQuery();

  const { t } = useTranslation();
  const title = t('Calculation');

  useErrorContext({
    title,
    source: IssueSource.quota,
  });

  if (!currencies || isLoading || !cofs || isLoadingCofs) {
    return null;
  }

  return (
    <CurrencyExchangerProvider currencies={currencies}>
      <CreateQuotaForm />
    </CurrencyExchangerProvider>
  );
};

type EditQuotaPageProps = {
  quotaId?: number;
  shipmentItemId?: number;
};

export const EditQuotaPage = (props: EditQuotaPageProps) => {
  const { quotaId, shipmentItemId } = props;
  const { id: idString } = useParams<{ id: string }>();
  const id = quotaId || Number.parseInt(idString);
  const isTask = !!quotaId;
  const url = `${id}`;

  const { data: quota } = useQuotasBackendQuery<QuotaCalculationResult>(url, ['quotas', id], {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { data: currencies } = useCurrencyRatesQuery();

  const { t } = useTranslation();
  const title = t('Calculation');

  useErrorContext({
    title,
    source: IssueSource.quota,
  });

  if (!currencies || !quota) {
    return null;
  }

  return (
    <CurrencyExchangerProvider currencies={currencies}>
      <EditQuotaForm quota={quota} shipmentItemId={shipmentItemId} isTask={isTask} />
    </CurrencyExchangerProvider>
  );
};

export const QuotasPage = () => {
  const { path } = useRouteMatch();
  const calculatorPath = `${path}/calculator`;
  const quotaPath = `${path}/calculator/:id(\\d+)`;
  const drawUpContractPath = `${path}/calculator/:id(\\d+)/drawupcontract`;

  return (
    <Switch>
      <Route exact path={calculatorPath} component={CreateQuotaPage} />
      <Route exact path={drawUpContractPath} component={DrawUpContract} />
      <Route path={quotaPath} component={EditQuotaPage} />
      <Route path={path} component={QuotaListPage} />
    </Switch>
  );
};
