import {
  TabPanel,
  Tabs,
  useTabs,
  CalculationSettingsForm,
  ModelSettingsForm,
  NotificationSettingsForm,
  RatingSettingsForm,
} from 'components';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { themeOrange as theme } from 'theme';
import {
  useCalculationMarginSettingsQuery,
  useCalculationSettingsQuery,
  useRatingSettingsQuery,
} from 'services';
import { PageTitle } from 'components/utils/pageTitle';

export const SettingsPage = () => {
  const { t } = useTranslation();
  const tabs = [t('Quota'), t('Notification'), t('Solution models'), t('Rating')];
  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;

  const pageTitle = t('Settings') + ' - Bumblebee';
  PageTitle(pageTitle);

  const { data: calculationDefaultData, isLoading: isLoadingCalculation } =
    useCalculationSettingsQuery();

  const { data: modelDefaultData, isLoading: isLoadingModel } = useCalculationMarginSettingsQuery();

  const { data: ratingDefaultData, isLoading: isLoadingRating } = useRatingSettingsQuery();

  if (
    isLoadingCalculation ||
    calculationDefaultData === undefined ||
    isLoadingModel ||
    modelDefaultData === undefined ||
    isLoadingRating ||
    ratingDefaultData === undefined
  ) {
    return null;
  }

  return (
    <>
      <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
      <SwipeableViews
        containerStyle={{
          transition: 'transform 0.6s ease-out 0s',
        }}
        springConfig={{
          duration: '0.6s',
          easeFunction: 'transform 0.6s ease-out 0s',
          delay: '0s',
        }}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={onChangeTabIndex}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <CalculationSettingsForm defaultValues={calculationDefaultData} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <NotificationSettingsForm />
        </TabPanel>
        <TabPanel value={tabIndex} index={2} dir={theme.direction}>
          <ModelSettingsForm defaultValues={modelDefaultData} />
        </TabPanel>
        <TabPanel value={tabIndex} index={3} dir={theme.direction}>
          <RatingSettingsForm defaultValues={ratingDefaultData} />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};
