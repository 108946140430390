import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  MenuItem,
  Portal,
  Paper,
} from '@material-ui/core';
import {
  ContractPaymentConditionType,
  ContractType,
  ContractViewModel,
  CounterpartyContractViewModel,
  PrepaymentAdvanceOrder,
  QuotaCalculationResult,
  SendQuotaToExecutionViewModel as ContractFormValues,
} from 'schema/serverTypes';
import { useIssueContractMutation } from 'services';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useToast, Grid, Input, Select, Button, Switch, DatePicker } from 'components';
import { useHistory } from 'react-router';
import { LesseeAutocomplete } from '../../../tasks/LesseeAutocomplete';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { Signer } from './Signer';
import { Requisite } from './Requisite';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    subtitle: {
      color: theme.palette.secondary.dark,
      fontWeight: 500,
    },
    panels: {
      marginBottom: 75,
    },
    link: {
      color: theme.palette.primary.main,
    },
    wrapper: {
      width: '100%',
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 1,
    },
    actionsTask: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      position: 'static',
      zIndex: 1,
      marginBottom: theme.spacing(2),
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
  })
);

export type ScorinModelFormProps = {
  quota: QuotaCalculationResult;
  contract: ContractViewModel;
  prepaymentPercents: any;
};

export const ScoringModelForm = (props: ScorinModelFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    quotaId = 0,
    lessee: lesseeInn = '',
    lesseeName: lesseeFullName = '',
    input: { currencySale, currencyLease },
  } = props.quota;

  const {
    leaseSubject,
    prepaymentPercents,
    prepaymentDayTime,
    prepaymentConditionType = ContractPaymentConditionType.None,
    prepaymentAdvanceOrder,
    paymentPercents,
    paymentDayTime,
    paymentConditionType = ContractPaymentConditionType.None,
    paymentCurrencyRate,
    expectedShippingDate,
    prefunding,
    shippingAddress,
    dealer,
    dealerHead,
    lessee,
    lesseeHead,
  } = props.contract;

  const defaultRequisite = useMemo(() => {
    return {
      bic: '',
      bank: '',
      account: '',
      correspondentAccount: '',
    };
  }, []);

  const defaultValues: ContractFormValues = useMemo(() => {
    const values = {
      lessee: {
        fullName: lesseeFullName,
        inn: lesseeInn,
        head: lessee?.heads.find((head) => head.id === lesseeHead?.id),
        requisite: lessee?.requisites.find((t) => t.isMain) ?? defaultRequisite,
      } as CounterpartyContractViewModel,
      dealer: {
        head: dealer?.heads.find((head) => head.id === dealerHead?.id),
        requisite: dealer?.requisites.find((t) => t.isMain) ?? defaultRequisite,
      } as CounterpartyContractViewModel,
      documentUrl: '',
      description: '',
      title: `№${quotaId} | ${leaseSubject}`,
      link: {
        url: `/quotas/calculator/${quotaId}`,
        title: `№${quotaId}`,
      },
      contractType: ContractType.Lease,
      contract: {
        prepaymentPercents: prepaymentPercents ?? 0,
        prepaymentDayTime: prepaymentDayTime ?? '',
        prepaymentConditionType,
        prepaymentAdvanceOrder:
          prepaymentAdvanceOrder ?? PrepaymentAdvanceOrder.OneTimeWithFirstPayment,
        paymentPercents: paymentPercents ?? 0,
        paymentDayTime: paymentDayTime ?? 0,
        paymentConditionType,
        paymentCurrencyRate: paymentCurrencyRate ?? '',
        expectedShippingDate: expectedShippingDate ?? '',
        prefunding: prefunding ?? true,
        shippingAddress: shippingAddress ?? '',
      },
    };

    return values;
  }, [
    lesseeFullName,
    lesseeInn,
    lessee?.heads,
    lessee?.requisites,
    lesseeHead?.id,
    dealer?.heads,
    dealer?.requisites,
    dealerHead?.id,
    defaultRequisite,
    quotaId,
    leaseSubject,
    prepaymentPercents,
    prepaymentDayTime,
    prepaymentConditionType,
    prepaymentAdvanceOrder,
    paymentPercents,
    paymentDayTime,
    paymentConditionType,
    paymentCurrencyRate,
    expectedShippingDate,
    prefunding,
    shippingAddress,
  ]);

  const { control, handleSubmit, setValue, watch, reset, getValues } = useForm({
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const toast = useToast();

  const { mutateAsync } = useIssueContractMutation<ContractFormValues, any>(quotaId, {
    method: 'PUT',
    onSuccess: (result) => {
      toast(
        <>
          Создана задача №
          <Link
            to={`/tasks/${result.issueId}`}
            style={{ color: theme.palette.primary.main }}
            className={classes.link}
          >
            {result.issueId}
          </Link>
        </>,
        'success'
      );
      history.push(`/quotas/calculator/${result.quotaId}`);
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: ContractFormValues) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const message = t('Required');
  const history = useHistory();

  const prepaymentPercents100 = watch('contract.prepaymentPercents');
  const paymentPercents100 = watch('contract.paymentPercents');

  useEffect(() => {
    if (prepaymentPercents100 || prepaymentPercents100 === 0) {
      setValue('contract.paymentPercents', 100 - prepaymentPercents100);
    }
  }, [prepaymentPercents100, setValue]);

  useEffect(() => {
    if (paymentPercents100 || paymentPercents100 === 0) {
      setValue('contract.prepaymentPercents', 100 - paymentPercents100);
    }
  }, [paymentPercents100, setValue]);

  const itemPrice = props.quota.input.itemPrice ?? 0;

  useEffect(() => {
    const prepaymentAmount = Number.isNaN(prepaymentPercents100)
      ? getValues('contract.prepaymentAmount')
      : (prepaymentPercents100 / 100.0) * itemPrice;
    setValue('contract.prepaymentAmount', prepaymentAmount);
  }, [prepaymentPercents100, itemPrice, setValue, getValues]);

  useEffect(() => {
    const paymentAmount = Number.isNaN(paymentPercents100)
      ? getValues('contract.paymentAmount')
      : (paymentPercents100 / 100.0) * itemPrice;
    setValue('contract.paymentAmount', paymentAmount);
  }, [paymentPercents100, itemPrice, setValue, getValues]);

  return (
    <form id="newTask-form" onSubmit={onSubmit}>
      <div className={classes.panels}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <Typography variant="subtitle1">Основные</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={6} xs={24}>
                <Select name="contractType" control={control} label={t('Contract type')}>
                  <MenuItem value={ContractType.Lease}>{t('ContractType.Lease')}</MenuItem>
                  <MenuItem value={ContractType.Loan}>{t('ContractType.Loan')}</MenuItem>
                  <MenuItem value={ContractType.LeaseNoVAT}>
                    {t('ContractType.LeaseNoVAT')}
                  </MenuItem>
                </Select>
              </Grid>
              <LesseeAutocomplete control={control} setValue={setValue} />
              <Grid item md={24} xs={24}>
                <Input
                  label={t('Link to documents')}
                  control={control}
                  name="documentUrl"
                  rules={{
                    required: {
                      value: true,
                      message,
                    },
                  }}
                />
              </Grid>
              <Grid item md={24} xs={24}>
                <Input
                  label={t('Description')}
                  control={control}
                  name="description"
                  multiline={true}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <Typography variant="subtitle1">{t('Conditions')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid md={5} xs={24} item>
                <Input
                  label={`${t('Sales prepayment in %')}`}
                  name="contract.prepaymentPercents"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                    pattern: /^[0-9,\-\s]+$/i,
                    min: {
                      value: 0,
                      message: 'Минимум 0%',
                    },
                    max: {
                      value: 100,
                      message: 'Максимум 100%',
                    },
                  }}
                />
              </Grid>
              <Grid md={5} xs={24} item>
                <Input
                  label={`${t('Advance payment period, days')}`}
                  name="contract.prepaymentDayTime"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                    pattern: /^[0-9,\-\s]+$/i,
                  }}
                />
              </Grid>
              <Grid xs={24} item>
                <Select
                  label={`${t('Prepayment conditions')}`}
                  name="contract.prepaymentConditionType"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                >
                  <MenuItem value={ContractPaymentConditionType.None}>&nbsp;</MenuItem>
                  <MenuItem value={ContractPaymentConditionType.AfterObligation}>
                    После исполнения Лизингополучателем своих обязательств по Договору лизинга по
                    уплате авансового платежа и комиссии за организацию лизинговой сделки.
                  </MenuItem>
                  <MenuItem value={ContractPaymentConditionType.UPD}>
                    Продавца следующих документов в оригинале: УПД / товарной накладной и
                    счета-фактуры, а также подписанного Акта приемки-передачи Оборудования без
                    каких-либо претензий Покупателя и/или Лизингополучателя по качеству, количеству
                    и комплектности Оборудования.
                  </MenuItem>
                  <MenuItem value={ContractPaymentConditionType.WrittenNotice}>
                    С момента получения Покупателем письменного уведомления о готовности
                    оборудования к отгрузке со склада Продавца.
                  </MenuItem>
                </Select>
              </Grid>

              <Grid md={5} xs={24} item>
                <Input
                  label={`${t('Sales payment in %')}`}
                  name="contract.paymentPercents"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                    pattern: /^[0-9,\-\s]+$/i,
                    min: {
                      value: 0,
                      message: 'Минимум 0%',
                    },
                    max: {
                      value: 100,
                      message: 'Максимум 100%',
                    },
                  }}
                />
              </Grid>
              <Grid item md={5} xs={24}>
                <Input
                  label={`${t('The deadline for payment of the main payment, days')}`}
                  name="contract.paymentDayTime"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                    pattern: /^[0-9,\-\s]+$/i,
                  }}
                />
              </Grid>
              <Grid item xs={24}>
                <Select
                  label={`${t('Payment conditions')}`}
                  name="contract.paymentConditionType"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                >
                  <MenuItem value={ContractPaymentConditionType.None}>&nbsp;</MenuItem>
                  <MenuItem value={ContractPaymentConditionType.AfterObligation}>
                    После исполнения Лизингополучателем своих обязательств по Договору лизинга по
                    уплате авансового платежа и комиссии за организацию лизинговой сделки.
                  </MenuItem>
                  <MenuItem value={ContractPaymentConditionType.UPD}>
                    Продавца следующих документов в оригинале: УПД / товарной накладной и
                    счета-фактуры, а также подписанного Акта приемки-передачи Оборудования без
                    каких-либо претензий Покупателя и/или Лизингополучателя по качеству, количеству
                    и комплектности Оборудования.
                  </MenuItem>
                  <MenuItem value={ContractPaymentConditionType.WrittenNotice}>
                    С момента получения Покупателем письменного уведомления о готовности
                    оборудования к отгрузке со склада Продавца.
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={24}>
                <Input
                  label={`${t('Payment currency rate')}`}
                  name="contract.paymentCurrencyRate"
                  control={control}
                  rules={{
                    required: {
                      value: !(currencySale === currencyLease),
                      message: t('Required'),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={22}>
                <DatePicker
                  label={`${t('Delivery time')}`}
                  name="contract.expectedShippingDate"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Switch
                  label={`${t('Prefunding')}`}
                  name="contract.prefunding"
                  control={control}
                  labelOn={''}
                  labelOff={''}
                />
              </Grid>
              <Grid item xs={24}>
                <Input
                  label={`${t('Shipping address')}`}
                  name="contract.shippingAddress"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={24}>
                <Select
                  label={t('Prepayment advance order')}
                  name="contract.prepaymentAdvanceOrder"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('Required'),
                    },
                  }}
                >
                  <MenuItem value={PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm}>
                    {t('PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm')}
                  </MenuItem>
                  <MenuItem value={PrepaymentAdvanceOrder.EvenlyDuringFirst12Months}>
                    {t('PrepaymentAdvanceOrder.EvenlyDuringFirst12Months')}
                  </MenuItem>
                  <MenuItem value={PrepaymentAdvanceOrder.OneTimeWithFirstPayment}>
                    {t('PrepaymentAdvanceOrder.OneTimeWithFirstPayment')}
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <Typography variant="subtitle1">Данные подписантов</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item xs={24}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t('Lessee')}
                </Typography>
              </Grid>
              <Signer
                control={control}
                item={lessee?.heads.find((head) => head.id === lesseeHead?.id)}
                type={'lessee'}
              />
              <Grid item xs={24}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t('Dealer')}
                </Typography>
              </Grid>
              <Signer
                control={control}
                item={dealer?.heads.find((head) => head.id === dealerHead?.id)}
                type={'dealer'}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <Typography variant="subtitle1">{t('Requisites')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item xs={24}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t('Lessee')}
                </Typography>
              </Grid>
              <Requisite
                control={control}
                setValue={setValue}
                item={lessee?.requisites.find((t) => t.isMain)}
                type={'lessee'}
              />
              <Grid item xs={24}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t('Dealer')}
                </Typography>
              </Grid>
              <Requisite
                control={control}
                setValue={setValue}
                item={dealer?.requisites.find((t) => t.isMain)}
                type={'dealer'}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>

      <Portal container={document.body}>
        <Paper square className={classes.actions}>
          <div className={classes.actionButton}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              {t('Create task')}
            </Button>
          </div>
          <div className={classes.actionButton}>
            <Button
              onClick={() => history.goBack()}
              color="primary"
              size="medium"
              variant="outlined"
            >
              {t('Cancel')}
            </Button>
          </div>
        </Paper>
      </Portal>
    </form>
  );
};
